import { gsap } from 'gsap';
import Flickity from 'flickity';

/**
 * Class representing components for managing different UI components.
 */
export class Components {
    
    /**
     * Constructs a Components object.
     */
    constructor() {
        this.hero();
        this.carousel();
        this.accordions();
        this.accordionsFilter();
        this.dateTrigger();
        this.filter();
        this.initializeFilters();
    }

    /**
     * Initializes the filter checkboxes based on URL parameters.
     */
     initializeFilters() {
        const urlParams = new URLSearchParams(window.location.search);
        const filters = urlParams.getAll('filter');
        
        if (filters.length > 0) {
            filters.forEach(filter => {
                const filterValues = filter.split(',');
                filterValues.forEach(value => {
                    $(`#filterForm input[type="checkbox"][value="${value}"]`)
                        .prop('checked', true);
                });
            });
        }
    }

    /**
     * Sets up event listeners for filter interaction.
     */
    filter() {
        $(document).on('click', '.carousel-cell', function() {
            const $checkbox = $(this).find('input[type="checkbox"]');
            $checkbox.prop('checked', !$checkbox.prop('checked'));
            $(this).toggleClass('selected');
        });

        $('#applyFilterBtn').on('click', () => {
            const selectedTerms = [];
            $('#filterForm').find('input[type="checkbox"]:checked').each(function() {
                selectedTerms.push($(this).val());
            });

            this.updateFilters(selectedTerms);
        });

        $('#resetFilterBtn').on('click', () => {
            $('#filterForm').find('input[type="checkbox"]').prop('checked', false).closest('.carousel-cell').removeClass('selected');
            this.updateFilters([]);
        });
    }

    /**
     * Updates the URL with the current filter terms.
     * @param {array} selectedTerms Array of selected filter terms.
     */
    updateFilters(selectedTerms) {
        const params = new URLSearchParams(window.location.search);
        params.delete('filter');
        if (selectedTerms.length > 0) {
            params.append('filter', selectedTerms.join(','));
        }
        const newUrl = `${window.location.pathname}?${params.toString()}${window.location.hash}`;
        window.location.href = newUrl; // Redirect to the new URL
    }

    isIOS() {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    dateTrigger() {
        // If the user is on iOS, apply the custom behavior
        if (this.isIOS()) {
            // Hide the actual date inputs on iOS
            $('#checkin, #checkout').css({
                'opacity': '0',
                'position': 'absolute',
                'pointer-events': 'none'
            });

            // Trigger the hidden date input field when the display input is clicked
            $('#checkin_display').on('click', function() {
                $('#checkin').focus();
            });

            $('#checkout_display').on('click', function() {
                $('#checkout').focus();
            });

            // Update the text field when a date is selected
            $('#checkin').on('change', function() {
                $('#checkin_display').val($(this).val());
            });

            $('#checkout').on('change', function() {
                $('#checkout_display').val($(this).val());
            });

        } else {
            // For desktop and other platforms, remove the custom display inputs
            $('#checkin_display').remove();
            $('#checkout_display').remove();

            // Show the actual date input fields
            $('#checkin, #checkout').css({
                'opacity': '1',
                'position': 'relative',
                'pointer-events': 'auto'
            });
        }
    }

    /**
     * Accordions.
     */
    accordions() {
        let section = $('article.accordion');
        let toggle = $('.heading', section);
    
        toggle.on('click', function() {
            let article = $(this).closest('article');
            let label = $('.label', article)

            article.toggleClass('open');
            
            if (article.hasClass('open')) {
                label.text('Read Less');
            } else {
                label.text('Read More');
            }
        });
    }

    accordionsFilter() {
        let toggle = $('.heading');
        let content = $('.content');
    
        toggle.on('click', function() {
            content.slideToggle();  
            $(this).closest('aside').toggleClass('open'); 
        });
    }

    /**
     * Set the hero margin based on #top
     */
    hero() {
        let section = $('#top').next('section');

        if (!section.length) {
            return;
        }

        $(window).on('load', function() {
            const top = $('#top').outerHeight();
            section.css('margin-top', top);
        });
    }
    
    /**
     * Initializes carousels using Flickity within sections of the main slider.
     *
     * This function searches for elements with the class `.main .slider` and initializes
     * Flickity carousels within each of them. It sets up the next/previous button controls,
     * updates their states based on the current slide index, and handles dot navigation.
     *
     * @returns {boolean} False if no sections are found; otherwise, void.
     */
    carousel() {
        let sections = gsap.utils.toArray('.slider');

        if (!sections.length) {
            return false;
        }

        sections.forEach(section => {
            let main = $('.main-carousel', section)[0],
                next = $(section).find('.next'),
                prev = $(section).find('.prev'),
                dots = $(section).find('.dots'),
                li = dots.find('li');

            var slider = new Flickity(main, {
                cellAlign: 'left',
                contain: false,
                pageDots: false,
                prevNextButtons: true,
                // adaptiveHeight: true,
            });

            // Event listener for slider select event
            slider.on('select', function() {
                let index = slider.selectedIndex;
                li.removeClass('active');
                li.eq(index).addClass('active');
            });

            // Event listener for dot clicks
            dots.on('click', 'li', function() {
                var index = $(this).index();
                slider.select(index);
            });

            /**
             * Event listener for the next button click.
             * Moves to the next slide.
             */
            next.on('click', function() {
                slider.next();
            });

            /**
             * Event listener for the previous button click.
             * Moves to the previous slide.
             */
            prev.on('click', function() {
                slider.previous();
            });
        });
    }
}
