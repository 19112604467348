import { gsap } from 'gsap/gsap-core';

/**
 * Class representing animations using GSAP.
 */
export class Animations {
    /**
     * Constructs an Animations object.
     */
    constructor() {
        let sections = gsap.utils.toArray('section');

        if(!sections.length) {
            return false;
        }

        sections.forEach(section => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: section,
                    start: 'top 85%',
                    toggleActions: 'play',
                }
            })
            
            /**
             * Fade in.
             */
            let fade = $('.fade-in', section);
            
            if(fade.length) {
                tl.from($('.fade-in > *', section), {
                    yPercent: 10,
                    duration: 1,
                    opacity: 0,
                    stagger: 0.25,
                    ease: 'power4.out',
                }, 0.5)
            }
        });

        this.parallax();
    }

    parallax() {
        const images = $('.parallax img');

        images.each(function() {
            const image = $(this);

            gsap.to(image, {
                yPercent: 25,
                ease: 'none',
                scrollTrigger: {
                    trigger: image[0],
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true
                }
            });
        });
    }
}