/**
 * Represents a Google Maps utility class.
 */
export class Google {
    /**
     * Constructs a new Google instance.
     */
    constructor() {
        // Initialize autocomplete and map on document ready
        $(document).ready(this.initAutocomplete.bind(this));
    }

    initAutocomplete() {
        $('.search-destination').each(function() {
            var destination = $(this),
                form = destination.closest('form'),
                latitudeInput = form.find('.search-latitude'),
                longitudeInput = form.find('.search-longitude');
    
            // Autocomplete options
            var options = {
                types: [], // Restrict to regions
                componentRestrictions: { country: 'UK' } // Restrict to United Kingdom
            };
    
            // Create autocomplete instance for each input
            var autocomplete = new window.google.maps.places.Autocomplete(destination[0], options);
    
            // Listen for place changes
            autocomplete.addListener('place_changed', function() {
                var place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.log("Place details not found for the selected location.");
                    clearCoordinates();
                    disableSubmit();
                    return;
                }
                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();
    
                // Set latitude and longitude values
                latitudeInput.val(lat);
                longitudeInput.val(lng);
    
                // Enable form submission when a valid place is selected
                enableSubmit();
            });
    
            // Listen for manual input changes to clear coordinates
            destination.on('input', function() {
                clearCoordinates();
                disableSubmit();
            });
    
            // Function to clear coordinates
            function clearCoordinates() {
                latitudeInput.val('');
                longitudeInput.val('');
            }
    
            // Function to disable form submission if lat and long are empty
            function disableSubmit() {
                if (latitudeInput.val() === '' && longitudeInput.val() === '') {
                    form.find('button[type="submit"]').prop('disabled', true);
                }
            }
    
            // Function to enable form submission
            function enableSubmit() {
                form.find('button[type="submit"]').prop('disabled', false);
            }
    
            // Disable form submission initially
            disableSubmit();
        });
    }
}

// Initialize the Google class
new Google();
